body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

div[role='tooltip'] {
  z-index: 1300;
}

/* .dx-datagrid-headers {
  background-color: rgba(39, 80, 155, 1);
}


.dx-header-row {
  color: #fff;
}

.dx-datagrid-nowrap .dx-header-row > td > .dx-datagrid-text-content {
  font-size: .92rem;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td .dx-sort-indicator, .dx-datagrid-headers .dx-datagrid-table .dx-row > td .dx-sort {
  color: #fff !important;
} */

.clickableDiv {
  cursor: pointer;
}

#bpgiDefault > div > label {
  max-width: 80px !important;
}

#dgfilterTable,
#billingProfileList,
#locationList,
#locationList2 .dx-datagrid-header-panel {
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-width: 1px;
}

.acidSelectFieldNowrap > label {
  white-space: nowrap;
}

#profile-list-search-input::placeholder {
  color: rgba(0, 0, 0, 0.25);
}
