.WrappedColumnClass {
  white-space: normal;
  word-wrap: break-word;
}

.dataGrid-max-width-small {
  max-width: 200px;
}
.dataGrid-max-width-mini {
  max-width: 150px;
}
.dataGrid-min-width {
  min-width: 150px;
}

.master-detail-caption {
  padding: 0 10px;
  font-size: 14px;
  font-weight: bold;
}
